import async from '../components/Async';

import {
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Home as HomeIcon,
  Sliders as SlidersIcon, PieChart, MessageCircle
} from 'react-feather';

// Report pages
import MarketView from '../pages/reports/components/MarketView';
import CompletedProjects from '../pages/reports/components/CompletedProjects';
import EmployeeDetails from '../pages/reports/components/EmployeeDetails';
import TimeAllocations from '../pages/reports/components/TimeAllocations';

// Facilitator Reports
import ScoreCardReport from '../pages/facilitatorReports/ScoreCardReport';
import EngagementGrowthReport from '../pages/facilitatorReports/EngagementGrowthReport';
import SkillGrowthReport from '../pages/facilitatorReports/SkillGrowthReport';
import TimeAllocationReport from '../pages/facilitatorReports/TimeAllocationReport';
import ConversationResponseReport from '../pages/facilitatorReports/ConversationResponseReport';

// Pages
const Dashboard = async(() => import('../pages/dashboard/Dashboard'));
const Conversations = async(() =>
  import('../pages/conversations/Conversations')
);
const Reports = async(() => import('../pages/reports/Reports'));

// Facilitator
const FacilitatorDashboard = async(() =>
  import('../pages/facilitator/FacilitatorDashboard')
);
const FacilitatorAIDashboard = async(() =>
  import('../pages/facilitator/FacilitatorAIDashboard')
);
const FacilitatorReports = async(() =>
  import('../pages/facilitatorReports/FacilitatorReports')
);

// Routes
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboard',
  nameDictionaryKey: 'DASHBOARD',
  icon: HomeIcon,
  component: Dashboard,
  containsHome: true,
  children: null,
};

const conversationsRoutes = {
  path: '/conversations/:channelId',
  to: '/conversations/general',
  name: 'Conversations',
  nameDictionaryKey: 'CONVERSATIONS',
  icon: MessageCircleIcon,
  component: Conversations,
  children: null,
};

export const reportsRoutes = {
  path: '/reports',
  name: 'Reports',
  nameDictionaryKey: 'REPORTS',
  icon: PieChartIcon,
  component: Reports,
  children: [
    {
      path: '/reports/market-view',
      name: 'Market View',
      nameDictionaryKey: 'LABEL_MARKET_VIEW',
      component: MarketView,
      desc: 'View rating points for all teams across months.',
      descDictionaryKey: 'LABEL_VIEW_RATING_POINTS_FOR_ALL_TEAMS_ACROSS_MONTHS',
    },
    {
      path: '/reports/completed-projects',
      name: 'Completed Tasks',
      nameDictionaryKey: 'LABEL_COMPLETED_TASKS',
      component: CompletedProjects,
      desc: 'Review your completed task information and feedback.',
      descDictionaryKey: 'LABEL_COMPLETED_TASKS_DESC',
    },
    {
      path: '/reports/employee-details',
      name: 'Employee Details',
      nameDictionaryKey: 'LABEL_EMPLOYEE_DETAILS',
      component: EmployeeDetails,
      desc: 'View progress of individual employees.',
      descDictionaryKey: 'LABEL_EMPLOYEE_DETAILS_DESC',
    },
    {
      path: '/reports/time-allocation',
      name: 'Time Allocation',
      nameDictionaryKey: 'LABEL_TIME_ALLOCATION',
      component: TimeAllocations,
      desc: 'View your cumulative token usage.',
      descDictionaryKey: 'LABEL_TIME_ALLOCATION_PLAYER_DESC',
    },
  ],
};

const facilitatorDashboardRoutes = {
  path: '/facilitator',
  name: 'Facilitator Dashboard',
  nameDictionaryKey: 'LABEL_FACILITATOR_DASHBOARD',
  icon: SlidersIcon,
  component: FacilitatorDashboard,
  containsHome: true,
  children: null,
};

const facilitatorAIDashboardRoutes = {
  path: '/facilitator/ai-dashboard',
  name: 'AI Chat Dashboard',
  icon: MessageCircle,
  component: FacilitatorAIDashboard,
  containsHome: true,
  children: null,
};

export const facilitatorReportRoutes = {
  path: '/facilitator/reports',
  name: 'Facilitator Reports',
  nameDictionaryKey: 'LABEL_FACILITATOR_REPORTS',
  component: FacilitatorReports,
  icon: PieChartIcon,
  children: [
    {
      path: '/facilitator/reports/score-card',
      name: 'Team Score Card',
      nameDictionaryKey: 'LABEL_TEAM_SCORE_CARD',
      component: ScoreCardReport,
      desc: 'View all points for all teams across months.',
      descDictionaryKey: 'LABEL_TEAM_SCORE_CARD_DESC',
    },
    {
      path: '/facilitator/reports/time-allocation',
      name: 'Time Allocation',
      nameDictionaryKey: 'LABEL_TIME_ALLOCATION',
      component: TimeAllocationReport,
      desc: 'Where did all the time go?',
      descDictionaryKey: 'LABEL_TIME_ALLOCATION_FACILITATOR_DESC',
    },
    {
      path: '/facilitator/reports/engagement-growth',
      name: 'Engagement Growth',
      nameDictionaryKey: 'LABEL_ENGAGEMENT_GROWTH',
      component: EngagementGrowthReport,
      desc: 'How has engagement changed over time.',
      descDictionaryKey: 'LABEL_ENGAGEMENT_GROWTH_DESC',
    },
    {
      path: '/facilitator/reports/skill-growth',
      name: 'Skill Growth',
      nameDictionaryKey: 'LABEL_SKILL_GROWTH',
      component: SkillGrowthReport,
      desc: 'How have skills grown over time.',
      descDictionaryKey: 'LABEL_SKILL_GROWTH_DESC',
    },
    {
      path: '/facilitator/reports/conversation-responses',
      name: 'Conversation Responses',
      nameDictionaryKey: 'LABEL_CONVERSATION_RESPONSES',
      component: ConversationResponseReport,
      desc: 'Responses to conversations.',
      descDictionaryKey: 'LABEL_CONVERSATION_RESPONSES_DESC',
    },
  ],
};

// Player specific routes
export const player = [dashboardRoutes, conversationsRoutes, reportsRoutes];

// Facilitator specific routes
export const facilitator = [
  facilitatorDashboardRoutes,
  facilitatorReportRoutes,
  facilitatorAIDashboardRoutes
];

// All routes
export default [dashboardRoutes, conversationsRoutes, reportsRoutes];
