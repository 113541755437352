import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import coverFile from '../../assets/img/cover.jpg';
import logoMark from '../../assets/img/logo-mark.png';
import { Input, Button } from 'reactstrap';
import moment from 'moment';
import useConfig from '../../hooks/use-config';

const SignIn = (props) => {
  const { handleSignIn } = props;
  const { tFallback } = useConfig();

  useEffect(() => {
    const parsedQueryStr = queryString.parse(window.location.search);
    if (parsedQueryStr.playerName) {
      handleSignIn(parsedQueryStr.playerName);
    }
  }, [handleSignIn]);

  const [playerName, setPlayerName] = useState('');
  const [invalidLoginMessage, setInvalidLoginMessage] = useState('');

  const handleLogin = async () => {
    const message = await props.handleSignIn(playerName.trim());
    if (message) {
      setInvalidLoginMessage(message);
    }
  };

  return (
    <React.Fragment>
      <div className="loginWrapper">
        <div className="coverPic">
          <img
            alt="MC"
            src={coverFile}
            data-src={coverFile}
            data-src-retina={coverFile}
          />
          <div className="coverCaption">
            <div className="coverTagline">
              {tFallback(
                'LABEL_REAL_WORLD_PRACTICE_WITHOUT_REAL_WORLD_CONSEQUENCES',
                'Real world practice without the real world consequences.'
              )}
            </div>
            <div className="coverCopyright">
              v5.7.4 -{' '}
              {tFallback('LABEL_ALL_RIGHTS_RESERVED', 'All rights reserved.')}{' '}
              &copy; {`${moment().year()} `}
              <a
                href="http://www.abilitie.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Abilitie Inc.
              </a>
            </div>
          </div>
        </div>
        <div className="loginContainer">
          <div className="loginForm">
            <div className="logoLink">
              <img src={logoMark} className="loginLogo" alt="MC Logo" />
              {tFallback(
                'LABEL_MANAGEMENT_CHALLENGE',
                'Management Challenge'
              ).toUpperCase()}
            </div>
            <div>
              <div className="formGroup">
                <label>{tFallback('LABEL_PLAYER_NAME', 'Player Name')}</label>
                <div className="controls">
                  <Input
                    bsSize="lg"
                    type="text"
                    name="playerName"
                    placeholder={tFallback(
                      'LABEL_ENTER_YOUR_PLAYER_NAME',
                      'Enter your player name'
                    )}
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ color: 'red', height: '22px', fontSize: '16px' }}>
                {invalidLoginMessage && <span>{invalidLoginMessage}</span>}
              </div>
              <Button
                disabled={!playerName}
                type="success"
                className="loginButton mt-1"
                onClick={handleLogin}
              >
                {tFallback('LABEL_SIGN_IN', 'Sign In')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
