import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import store from '../redux/store/index';
import {
  SET_FACILITATOR_LATEST_DASHBOARD_REPORT_STATE,
  SET_FACILITATOR_LATEST_REPORT_STATE,
  SET_FACILITATOR_REPORT_STATE,
  SET_FACILITATOR_SESSION_ID,
  SET_FACILITATOR_STATE,
  UPDATE_FILTERED_TEAMS,
  SET_FACILITATOR_SECONDS_REMAINING,
} from '../redux/redux-constants';
import { Sliders } from 'react-feather';

export async function getFacilitatorState(optionalSessionId) {
  const payload = await callApi('getFacilitatorState', {}, optionalSessionId);

  setState(SET_FACILITATOR_STATE, payload, 'game', getFacilitatorState);
}

export async function getFacilitatorSecondsRemaining(optionalSessionId) {
  const payload = await callApi(
    'getFacilitatorSecondsRemaining',
    {},
    optionalSessionId
  );
  setState(
    SET_FACILITATOR_SECONDS_REMAINING,
    payload,
    'game',
    getFacilitatorSecondsRemaining
  );
}

export async function updateParticipantMaterials(sessionId, url) {
  await callApi('updateParticipantMaterials', { url }, sessionId);
}

function setState(actionType, payload, stateType, retryFn) {
  if (!_.isEmpty(payload) || typeof payload === 'number') {
    store.dispatch({
      type: actionType,
      payload,
    });
  }
  // else {
  //
  //   const errorMessage = `Error fetching ${stateType} state, will retry after timeout.`;
  //   toastr.light(
  //     'API ERROR',
  //     {
  //       component: <ApiErrorMessage message={errorMessage} />,
  //       icon: 'error',
  //       status: 'error',
  //       timeOut: 15000,
  //       showCloseButton: true,
  //       removeOnHoverTimeOut: 0,
  //       onHideComplete: retryFn
  //     }
  //   );
  //
  // }
}

/* =========================== UPDATES */
export async function createSession(sessionId) {
  store.dispatch({
    type: SET_FACILITATOR_SESSION_ID,
    payload: sessionId,
  });

  const result = await callApi('createSession', {}, sessionId);

  if (result !== 'success') {
    store.dispatch({
      type: SET_FACILITATOR_SESSION_ID,
      payload: null,
    });
  }
}

export async function getAWSConfigFile(fileName) {
  return await callApi('getAWSConfigFile', { fileName });
}

export async function resetConfiguration() {
  return await callApi('resetConfiguration');
}

export async function getFacilitatorReportState() {
  const payload = await callApi(
    'getFacilitatorReportState',
    {},
    '',
    '/reports/'
  );
  setState(
    SET_FACILITATOR_REPORT_STATE,
    payload,
    'report',
    getFacilitatorReportState
  );
}

export async function getFacilitatorLatestReportState() {
  const payload = await callApi(
    'getFacilitatorLatestReportState',
    {},
    '',
    '/reports/'
  );
  setState(
    SET_FACILITATOR_LATEST_REPORT_STATE,
    payload,
    'report',
    getFacilitatorLatestReportState
  );
}

export async function getFacilitatorLatestDashboardReportState() {
  const payload = await callApi(
    'getFacilitatorLatestDashboardReportState',
    {},
    '',
    '/reports/'
  );

  setState(
    SET_FACILITATOR_LATEST_DASHBOARD_REPORT_STATE,
    payload,
    'report',
    getFacilitatorLatestDashboardReportState
  );
}

export function updateFilteredTeams(filteredTeams) {
  setState(UPDATE_FILTERED_TEAMS, filteredTeams, 'report', updateFilteredTeams);
}

export async function getAWSConfigList() {
  const configList = await callApi('getAWSConfigList');
  return _.map(configList, 'key');
}

export async function resetSession() {
  await callApi('resetSession');
  getFacilitatorReportState();
}

export async function startRound(optionalTimeLimitMin) {
  const timeLimit = optionalTimeLimitMin || 30;
  callApi('startRound', { timeLimit });
}

export async function endRound(optionalTimeLimitMin) {
  callApi('endRound');
}

export async function rollbackRound() {
  callApi('rollbackRound');
}

export async function createCompany() {
  callApi('createCompany');
}

export async function changePlayerName(oldPlayerName, newPlayerName) {
  callApi('changePlayerName', { oldPlayerName, newPlayerName });
}

export async function changeRoleName(oldRoleName, newRoleName) {
  callApi('changeRoleName', { oldRoleName, newRoleName });
}

export async function changeCompanyName(oldCompanyName, newCompanyName) {
  callApi('changeCompanyName', { oldCompanyName, newCompanyName });
}

export async function deleteCompany(companyName) {
  callApi('deleteCompany', { companyName });
}

export async function toggleCanManagersCollaborate(companyName) {
  callApi('toggleCanManagersCollaborate', { companyName });
}

export async function setBlackoutScreen(isBlackoutScreenVisible, tFallback) {
  const result = await callApi('setBlackoutScreen', {
    isBlackoutScreenVisible,
  });
  if (result === 'success') {
    toastr.light(
      tFallback('LABEL_DISABLE_PLAY_SUCCESS', 'Successfully disabled play'),
      {
        status: 'success',
        icon: <Sliders />,
      }
    );
  } else {
    toastr.error(
      tFallback('LABEL_DISABLE_PLAY_FAILURE', 'Failed to toggle disable play')
    );
  }
}

export async function setActiveConversation(conversationStringId, tFallback) {
  const result = await callApi('setActiveConversation', {
    conversationStringId,
  });
  if (result === 'success') {
    toastr.light(
      _.startCase(conversationStringId) +
        ' ' +
        tFallback('LABEL_CONVERSATION_ACTIVE', 'conversation active'),
      {
        status: 'success',
        icon: <Sliders />,
      }
    );
  } else {
    toastr.error('Conversation could not be activated');
  }
}

export async function broadcastPlayerMessage(message) {
  callApi('broadcastPlayerMessage', { message });
}

export async function updateTimeLimit(timeLimitDelta) {
  callApi('updateTimeLimit', { timeLimitDelta });
}

export async function uploadConfigFile(fileData) {
  await uploadFileToApi('putAWSConfigFile', { fileData });
}

async function uploadFileToApi(endpoint, optionalParameters) {
  const parameters = optionalParameters || {};
  const sessionId = store.getState().facilitatorState.selectedSessionId;
  const requestUrl =
    getBaseUrl() +
    '/facilitator/' +
    endpoint +
    '?' +
    getUrlParameters({ sessionId });

  const data = {
    sessionId,
    ...parameters,
  };

  return await postApiEndpoint(requestUrl, data);
}

// ************ Helpers
async function callApi(
  endpoint,
  optionalParameters,
  optionalSessionId,
  optionalEndpointRoot
) {
  const parameters = optionalParameters || {};
  const sessionId =
    optionalSessionId ||
    store.getState().facilitatorState.selectedSessionId ||
    '';

  const options = {
    sessionId,
    ...parameters,
  };

  const apiCall = optionalEndpointRoot || '/facilitator/';

  const requestUrl =
    getBaseUrl() + apiCall + endpoint + '?' + getUrlParameters(options);

  return await callApiEndpoint(requestUrl);
}

async function callApiEndpoint(requestUrl) {
  try {
    const result = await axios(requestUrl, {
      headers: { 'Cache-Control': 'no-cache' },
    });

    return result.data;
  } catch (error) {
    const errorMessage =
      (error.response && error.response.statusText) ||
      error ||
      'General API error, is app connected?';

    console.error(
      'callApiEndpoint request:',
      requestUrl,
      'error:',
      errorMessage
    );
    toastr.error('API FAILURE', errorMessage);
    return false;
  }
}

async function postApiEndpoint(requestUrl, params) {
  try {
    let formData = new FormData();
    formData.append('fileData', params.fileData);

    const result = await axios.post(requestUrl, formData, {
      headers: { 'Cache-Control': 'no-cache', enctype: 'multipart/form-data' },
    });

    if (typeof result.data === 'string' && result.data !== 'success') {
      toastr.warning('Warning', result.data);
    }

    return result.data;
  } catch (error) {
    const errorMessage = error || 'General API error, is app connected?';
    console.error(
      'callApiEndpoint request:',
      requestUrl,
      'error:',
      errorMessage
    );
    toastr.error('API FAILURE', errorMessage);
    return false;
  }
}

function getBaseUrl() {
  const { host, protocol } = window.location;
  return `${protocol}//${host.replace('3000', '8080')}/api`;
}

function getUrlParameters(options) {
  return Object.keys(options)
    .map((key) => `${key}=${encodeURIComponent(options[key])}`)
    .join('&');
}
